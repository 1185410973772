<template>
  <ValidationObserver ref="form" v-slot="{ invalid }">
    <p class="text-center text-red my-n2 py-n2" style="color: red;">{{ authUser.error && authUser.error.non_field_errors? authUser.error.non_field_errors[0] : '' }}</p>
    <form class="form-texts" :class="{'mt-4' : authUser.error && authUser.error.non_field_errors? false: true}" @submit.prevent="onSubmit">
      <ValidationProvider vid="email" name="E-mail" rules="required|email" v-slot="{ errors }">
        <div class="form-group">
          <label for="emailInput">Email address</label>
          <input type="email" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="emailInput" aria-describedby="emailHelp"
                 v-model="user.email" placeholder="Enter your email address" required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider vid="password" name="Password" rules="required" v-slot="{ errors }">
        <div class="form-group">
          <label for="passwordInput">Password</label>
          <router-link to="/auth/password-reset" class="float-right text-dark">
            Forgot password?
          </router-link>
          <input :type="textPassword? 'text' : 'password'"  :class="'form-control password-input mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="passwordInput"
                 v-model="user.password" placeholder="Password" required>
                 <i class="las la-eye-slash cursor-pointer float-right text-dark" @click="textPassword = !textPassword"></i>

          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <div class="hidden w-100">
        <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
          <input type="checkbox" class="custom-control-input" id="customCheck1">
          <label class="custom-control-label" for="customCheck1">Remember Me</label>
        </div>
      </div>
       <div class="sign-info text-center sign-in-button">
          <button type="submit" class="d-block w-100 mb-2 sign-in-form-btn" :disabled="invalid">Sign in</button>
          <span class="text-dark text-center dark-color d-inline-block line-height-2">Don't have an account? <router-link to="/auth/sign-up" class="">Sign up</router-link></span>
       </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SignInForm',
  components: { },
  props: ['email', 'password'],
  data: () => ({
    user: {
      email: '',
      password: ''
    },
    textPassword: false
  }),
  created () {
    this.goHome()
  },
  computed: {
    ...mapGetters({
      stateUsers: 'Setting/usersState',
      authUser: 'Setting/authUserState'
    })
  },
  watch: {
    authUser (newValue) {
      if (newValue.auth) {
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect)
        } else {
          this.$router.push({ name: 'shop.home' })
        }
      }
    }
  },
  methods: {
    onSubmit () {
      this.$store.dispatch('Setting/authUserAction', this.user)
    },
    goHome () {
      if (this.authUser.auth) {
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect)
        } else {
          this.$router.push({ name: 'shop.home' })
        }
      }
    }
  }
}
</script>

<style>

</style>

<template class="main-login-section">

  <div class="sign-in-container">
      <div class="logo">
        <img src="@/assets/images/logo.png" alt="logo" height="50vh" />
      </div>
    <div class="sign-in-content">

       <div>
        <div class="sign-in-title text-center">
          <h3 class="mb-0 title">Welcome Back</h3>
        </div>
        <div class="sign-in-description text-center">

          <p>Enter your email address and password to discover more</p>
        </div>
      </div>

      <sign-in-form email="admin@demo.com" password="admin123" class="sign-in-form"></sign-in-form>

  </div>
</div>
</template>

<script>
import SignInForm from './Forms/SignInForm'

export default {
  name: 'SignIn1',
  components: { SignInForm },
  data: () => ({}),
  mounted () {
  },
  methods: {
  }
}
</script>

<style >

</style>
